//start
function equalHeight(items) {
    var highest = 0;
    items.each(function() {
        thisHeight = $(this).height();
        if(thisHeight > highest) {
            highest = thisHeight;
        }
    });
    items.height(highest);
}
$(document).ready(function(){
    equalHeight($(".price-service-items__text"));

    /*if ($(window).width() < 992 ) {
        equalHeight($(".about__wrap .about__descr"));
    }*/
});
//end



//START
$('.repeat-videos-carousel').owlCarousel({
    center: false,
    loop: true,
    margin: 20,
    dots: true,
    dotsEach: 1,
    items: 3,
    smartSpeed: 2000,
    //autoplay:true,
    //autoplayTimeout:2000,
    //autoplayHoverPause:true
    responsive:{
        320: {
            items: 1
        },
        576: {
            items: 1
        },
        768: {
          items: 2,
        },
        1024: {
          items: 3
        },
        1319: {
          items: 3
        },
        1711: {
          items: 3
        }
   }
});
$('.repeat-videos-arrows__prev').on("click", function(){
 $('.repeat-videos-carousel').trigger("prev.owl.carousel");
});

$('.repeat-videos-arrows__next').on("click", function(){
  $('.repeat-videos-carousel').trigger("next.owl.carousel");
});
//END


//START
$('.portfolio-single-carousel__items').owlCarousel({
    center: false,
    loop: true,
    margin: 20,
    dots: true,
    items: 1,
    smartSpeed: 2000,
    //autoplay:true,
    //autoplayTimeout:2000,
    //autoplayHoverPause:true
});
$('.portfolio-single-carousel__prev').on("click", function(){
  $('.portfolio-single-carousel__items').trigger("prev.owl.carousel");
});

$('.portfolio-single-carousel__next').on("click", function(){
  $('.portfolio-single-carousel__items').trigger("next.owl.carousel");
});


$(".portfolio-single-carousel__miniimage").on('click', function(){
    var this_attr = $(this).attr('data-num');
    $(".portfolio-single-carousel__miniimage").removeClass('active');
    $(this).addClass('active');
    $('.portfolio-single-carousel__items').trigger('to.owl.carousel', this_attr, 300);
});
$('.bl-third__carousel').on('changed.owl.carousel', function (e) {
     var currentindex = e.item.index;
     $(".portfolio-single-carousel__miniimage").removeClass('active');
     $('.portfolio-single-carousel__miniimage[data-num="'+currentindex+'"]').addClass('active');
     //return currentindex;
});
//END


//start
$(document).on('click', '.md-menu', function() {
    $('.site-menu').addClass('__active');
});

$(document).on('click', '.site-menu__close', function() {
    $('.site-menu').removeClass('__active');
});
//END


//wowJs
var wowJs = function() {
  var wow = new WOW;
  wow.init();
};
wowJs();
//wowJs END


//start
/*jQuery(document).ready(function($) { 

    $('.header-menu__item').click(function () {
        $('.header-menu__item').removeClass('active');
        $(this).addClass('active');

        var activeIndex = $(this).index();
        localStorage.setItem('headerMenu', activeIndex);
    });    

});


jQuery(document).ready(function($) { 

    var activeIndex = localStorage.getItem('headerMenu');

    if (activeIndex === null) {
      console.log('nothing stored');
    } else {
      $('.header-menu__item').removeClass('active');
      $('.header-menu__item').eq(activeIndex).addClass('active');
    }

});*/
//end